import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "./../components/layout/Main"
import BlogRoll from "./../components/BlogRoll"
import { Heading } from "../components/styled/BlogRollHeading"

export const IndexPageTemplate = ({ title, posts }) => (
  <div>
    <Heading>Moji postovi</Heading>
    <BlogRoll posts={posts} />
  </div>
)

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
}

const IndexPage = ({ data }) => {
  //   const { frontmatter } = data.markdownRemark
  const posts = data?.allMarkdownRemark?.edges

  return (
    <Layout>
      <IndexPageTemplate title={"Dafna style blog"} posts={posts} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, draft: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            category
            description
            date(formatString: "DD-MMM-YYYY")
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
